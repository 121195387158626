import _ from "lodash"

const emptyBlack = "/images/empty-black.jpg"
const emptyWhite = "/images/empty-white.jpg"

export default {
  postSingleImage(post) {
    return findImageSize(post, [
      "thumbnail.sizes._900_450_crop",
      "thumbnail.sizes.large"
    ]) || emptyBlack
  },

  postGridImage(post) {
    return findImageSize(post, [
      "thumbnail.sizes._450_225_crop",
      "thumbnail.sizes.medium_large",
      "thumbnail.sizes.medium"
    ]) || emptyBlack
  },

  postListImage(post) {
    return findImageSize(post, [
      "thumbnail.sizes._900_450_crop",
      "thumbnail.sizes.medium_large",
      "thumbnail.sizes.medium"
    ]) || emptyBlack
  },

  postMiniImage(post) {
    return findImageSize(post, [
      "thumbnail.sizes.thumbnail"
    ]) || emptyBlack
  },
  
  productGridImage(post) {
    return findImageSize(post, [
      "thumbnail.sizes.medium"
    ]) || emptyWhite
  },

  productSingleImage(post) {
    return findImageSize(post, [
      "thumbnail.sizes.large",
    ]) || emptyWhite
  },

  bannerImage(post) {
    return findImageSize(post, [
      "acf.banner_image.sizes._1920_850_crop",
    ]) || emptyBlack
  },

  awardImage(image) {
    return findImageSize(image, [
      "sizes._800_480_crop",
      "sizes.medium_large"
    ]) || emptyBlack
  },

  pageBannerImage(image) {
    return findImageSize(image, [
      "sizes._1920_400_crop",
      "sizes._1920_500_crop"
    ]) || emptyBlack
  },

  textRightLeftImage(image) {
    return findImageSize(image, [
      "sizes._700_560_crop",
      "sizes.thumbnail"
    ]) || emptyBlack
  },
}

function findImageSize(post, sizes) {
  for (const size of sizes) {
    const url = _.get(post, size)
    if (url) return url
  }
}
