import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Breadcrumb from "./breadcrumb"
import Thumbnail from "../domain/thumbnail"

const PageBanner = ({ post, parentPost, src, title, link, parent_title, parent_link, theme }) => {
  let items = [{ label: "Home", link: "/" }]

  let page_title_color = {
    white: '#FFF',
    dark: '#000'
  }[theme]

  if (parentPost && parentPost.acf) {
    parent_title = parentPost.acf.page_title
    page_title_color = parentPost.acf.page_title_color
    src = Thumbnail.pageBannerImage(parentPost.acf.page_image)
  }

  if (parent_title && parent_link) items.push({ label: parent_title, link: parent_link })

  if (post && post.acf) {
    title = post.acf.page_title
    page_title_color = post.acf.page_title_color
    src = Thumbnail.pageBannerImage(post.acf.page_image)
  }

  if (title) items.push({ label: title, link: link })

  if (!page_title_color) page_title_color = "#FFFFFF"

  items = items.slice(0, 2)

  return (
    <section className="page-banner-component">
      <LazyLoadImage src={src} alt={title} className="page-banner-bg" />
      <div className="page-banner-content is-padding-horizontal-4-touch">
        <div className="page-banner-content-inner">
          <div className="is-margin-bottom-4">
            <Breadcrumb center={true} theme={theme} items={items} color={page_title_color} />
          </div>

          <h1 className="is-size-1 has-text-weight-bold" style={{ color: page_title_color }}>
            {title}
          </h1>
        </div>
      </div>
    </section>
  )
}

export default PageBanner
