import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PageBanner from "../../components/page-banner"
import ContactFormWithInfo from "../../components/contact-form-with-info"

const Page = ({ data }) => {
  const title = "Quero ser um representante"
  const post = data.wordpressMainPage

  return (
    <Layout>
      <SEO post={post} />
      <PageBanner post={post} />
      <ContactFormWithInfo
        button_id="quero-ser-um-representante-button"
        post_id={post.post_id}
        title={title}
        last_input_name="CNPJ"
        last_input_placeholder="CNPJ"
        last_input_validation="cnpj"
        use_cities={true}
      />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    wordpressMainPage(name: {eq: "quero-ser-um-representante"}) {
      ...MainPageFull
    }
  }
`
